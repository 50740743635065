import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type CancelFlightByPadMutationVariables = Types.Exact<{
  flight: Types.Scalars['Int']
}>

export type CancelFlightByPadMutation = {
  __typename?: 'Mutation'
  cancelFlightByPAD: boolean
}

export const CancelFlightByPadDocument = gql`
  mutation CancelFlightByPAD($flight: Int!) {
    cancelFlightByPAD(input: { flight: $flight })
  }
`
export type CancelFlightByPadMutationFn = Apollo.MutationFunction<
  CancelFlightByPadMutation,
  CancelFlightByPadMutationVariables
>

/**
 * __useCancelFlightByPadMutation__
 *
 * To run a mutation, you first call `useCancelFlightByPadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFlightByPadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFlightByPadMutation, { data, loading, error }] = useCancelFlightByPadMutation({
 *   variables: {
 *      flight: // value for 'flight'
 *   },
 * });
 */
export function useCancelFlightByPadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelFlightByPadMutation,
    CancelFlightByPadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelFlightByPadMutation,
    CancelFlightByPadMutationVariables
  >(CancelFlightByPadDocument, options)
}
export type CancelFlightByPadMutationHookResult = ReturnType<
  typeof useCancelFlightByPadMutation
>
export type CancelFlightByPadMutationResult =
  Apollo.MutationResult<CancelFlightByPadMutation>
export type CancelFlightByPadMutationOptions = Apollo.BaseMutationOptions<
  CancelFlightByPadMutation,
  CancelFlightByPadMutationVariables
>
