import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAuth } from '../AuthProvider'

type DisclaimerContextType = {
  accept: () => void
  isAccepted: boolean
}

const DisclaimerContext = createContext<DisclaimerContextType | null>(null)

const DisclaimerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false)
  const { auth } = useAuth()

  useEffect(() => {
    if (!auth) {
      setDisclaimerAccepted(false)
    }
  }, [auth])

  const accept = () => {
    setDisclaimerAccepted(true)
  }

  return (
    <DisclaimerContext.Provider
      value={{ accept, isAccepted: disclaimerAccepted }}
    >
      {children}
    </DisclaimerContext.Provider>
  )
}

export const useDisclaimer = () => {
  const value = useContext(DisclaimerContext)

  if (value === null) {
    throw new Error('useDisclaimer must be used within <DisclaimerProvider />')
  }
  return value
}

export default DisclaimerProvider
