import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Select,
  Stack,
  StackDivider,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import CountryOptions from '../CountryOptions'
import FlightDetails from '../FlightDetails'
import {
  AddressInfo,
  PassengerInfo,
  PassengerPageInfo,
} from '../Flights/Flights'
import Input from '../Input'
import Label from '../Label'
import Loader from '../Loader'
import NationalityOptions from '../NationalityOptions'
import SectionHeader from '../SectionHeader'
import Steps from '../Steps'
import { usePassengerFormQuery } from './query.generated'

type PassengerFormFields = {
  passenger: PassengerInfo
  additionalPassenger: PassengerInfo[]
  contactAddress: AddressInfo
  invoiceAddress: AddressInfo
  isSameInvoiceAddress: boolean
}

type PassengerFormProps = {
  defaultValues?: PassengerFormFields
  onBack: () => void
  onContinue: (pageInfo: PassengerPageInfo) => void
  selectedFlight: number
}

const PassengerForm: React.FC<PassengerFormProps> = ({
  defaultValues,
  onBack,
  onContinue,
  selectedFlight,
}) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<PassengerFormFields>({
    defaultValues: {
      passenger: { nationality: '' },
      contactAddress: { country: '' },
      invoiceAddress: { country: '' },
      isSameInvoiceAddress: true,
      ...defaultValues,
    },
  })

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'additionalPassenger',
    rules: { maxLength: 5 },
  })

  const { data, error, loading } = usePassengerFormQuery({
    variables: { id: selectedFlight },
  })

  const handleAdd = () => {
    append({
      firstname: '',
      lastname: '',
      birthdate: '',
      birthplace: '',
      nationality: '',
      passnumber: '',
      passIssueDate: '',
      passExpiryDate: '',
    })
  }

  const handleCancel = () => {
    onBack()
  }

  const handleContinue = (values: PassengerFormFields) => {
    const flight = data?.legs[0]

    if (flight) {
      onContinue({
        additionalPassenger: values.additionalPassenger,
        flightInfo: {
          id: selectedFlight,
          departure: {
            icao: flight.departure.airport.icao || '',
            name: flight.departure.airport.name || '',
          },
          destination: {
            icao: flight.destination.airport.icao || '',
            name: flight.destination.airport.name || '',
          },
          aircraft: flight.aircraft?.type || '',
          date: flight.departure.date.local || '',
          departureTime: flight.departure.time.local,
          arrivalTime: flight.destination.time.local,
          price: {
            luxuryTax: flight.prices.luxuryTax,
            net: flight.prices.net,
            serviceFee: flight.prices.serviceFee,
            taxes: flight.prices.taxes,
          },
        },
        passenger: values.passenger,
        contactAddress: {
          ...values.contactAddress,
          firstname: values.passenger.firstname,
          lastname: values.passenger.lastname,
        },
        invoiceAddress: values.isSameInvoiceAddress
          ? {
              ...values.contactAddress,
              firstname: values.passenger.firstname,
              lastname: values.passenger.lastname,
            }
          : values.invoiceAddress,
        isSameInvoiceAddress: values.isSameInvoiceAddress,
      })
    }
  }

  const handlePreFill = () => {
    setValue('passenger.firstname', 'Nick')
    setValue('passenger.lastname', 'Wittland')
    setValue('passenger.birthdate', '1987-03-11')
    setValue('passenger.birthplace', 'Verl')
    setValue('passenger.nationality', 'German')
    setValue('passenger.passnumber', '456')
    setValue('passenger.passIssueDate', '2018-07-16')
    setValue('passenger.passExpiryDate', '2028-07-15')
    setValue('contactAddress.street', 'Sperberweg 4a')
    setValue('contactAddress.postalCode', '33142')
    setValue('contactAddress.city', 'Büren')
    setValue('contactAddress.country', 'Germany')
    setValue('contactAddress.phone', '123')
    setValue('contactAddress.mail', 'nick.wittland@gmx.de')
  }

  const flight = data?.legs[0]

  return (
    <form onSubmit={handleSubmit(handleContinue)}>
      <Box pb={8} pt={16}>
        <Steps
          steps={[
            { name: 'Passenger details', status: 'current' },
            // { name: 'Contact details', status: 'upcoming' },
            { name: 'Payment details', status: 'upcoming' },
            { name: 'Confirmation', status: 'upcoming' },
          ]}
        />
      </Box>
      {loading ? (
        <Loader />
      ) : error || !data ? (
        <>Error</>
      ) : (
        <>
          <FlightDetails
            arrivalTime={flight?.destination.time.local || ''}
            date={flight?.departure.date.local || ''}
            departure={flight?.departure.airport || { icao: '', name: '' }}
            destination={flight?.destination.airport || { icao: '', name: '' }}
            time={flight?.departure.time.local || ''}
          />
          <Stack divider={<StackDivider />} marginTop={16} spacing={8}>
            <Box>
              <SectionHeader
                description="Please enter the personal data about the traveller"
                title="Personal data"
              />

              <Box
                display="grid"
                rowGap={6}
                columnGap={4}
                gridTemplateColumns={{
                  base: 'repeat(1, minmax(0,1fr))',
                  sm: 'repeat(2, minmax(0,1fr))',
                  md: 'repeat(3, minmax(0,1fr))',
                }}
              >
                <FormControl
                  isInvalid={errors.passenger?.firstname !== undefined}
                >
                  <Label
                    color="gray.700"
                    fontSize="sm"
                    fontWeight="medium"
                    htmlFor="firstname"
                  >
                    First name
                  </Label>
                  <Input
                    autoComplete="off"
                    fontSize={{ sm: 'sm' }}
                    id="firstname"
                    {...register('passenger.firstname', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.firstname &&
                      errors.passenger.firstname.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.passenger?.middlename !== undefined}
                >
                  <Label htmlFor="middlename">Middle name</Label>
                  <Input
                    autoComplete="off"
                    id="middlename"
                    {...register('passenger.middlename')}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.middlename &&
                      errors.passenger.middlename.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  gridColumn={{ base: 1, sm: '1 / span 2' }}
                  gridColumnStart={1}
                  isInvalid={errors.passenger?.lastname !== undefined}
                >
                  <Label htmlFor="lastname">Last name</Label>
                  <Input
                    autoComplete="off"
                    id="lastname"
                    {...register('passenger.lastname', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.lastname &&
                      errors.passenger.lastname.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  gridColumnStart={1}
                  isInvalid={errors.passenger?.birthdate !== undefined}
                >
                  <Label htmlFor="birthdate">Date of birth</Label>
                  <Input
                    autoComplete="off"
                    id="birthdate"
                    max={dayjs().format('YYYY-MM-DD')}
                    type="date"
                    {...register('passenger.birthdate', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.birthdate &&
                      errors.passenger.birthdate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.passenger?.birthplace !== undefined}
                >
                  <Label htmlFor="birthplace">Place of birth</Label>
                  <Input
                    autoComplete="off"
                    id="birthplace"
                    {...register('passenger.birthplace', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.birthplace &&
                      errors.passenger.birthplace.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.passenger?.nationality !== undefined}
                >
                  <Label htmlFor="nationality">Nationality</Label>
                  <Select
                    autoComplete="off"
                    id="nationality"
                    {...register('passenger.nationality', {
                      required: 'This is required',
                    })}
                  >
                    <NationalityOptions />
                  </Select>
                  <FormErrorMessage>
                    {errors.passenger?.nationality &&
                      errors.passenger.nationality.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.passenger?.passnumber !== undefined}
                >
                  <Label htmlFor="passnumber">Passport No.</Label>
                  <Input
                    autoComplete="off"
                    id="passnumber"
                    {...register('passenger.passnumber', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.passnumber &&
                      errors.passenger.passnumber.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.passenger?.passIssueDate !== undefined}
                >
                  <Label htmlFor="passIssueDate">Issue date</Label>
                  <Input
                    autoComplete="off"
                    id="passIssueDate"
                    max={dayjs().format('YYYY-MM-DD')}
                    min={dayjs().subtract(10, 'years').format('YYYY-MM-DD')}
                    type="date"
                    {...register('passenger.passIssueDate', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.passIssueDate &&
                      errors.passenger.passIssueDate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.passenger?.passExpiryDate !== undefined}
                >
                  <Label htmlFor="passExpiryDate">Expiration date</Label>
                  <Input
                    autoComplete="off"
                    id="passExpiryDate"
                    max={dayjs().add(25, 'years').format('YYYY-MM-DD')}
                    min={dayjs().format('YYYY-MM-DD')}
                    type="date"
                    {...register('passenger.passExpiryDate', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.passenger?.passExpiryDate &&
                      errors.passenger.passExpiryDate.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <SectionHeader
                description="Please give us information how we can get in contact with you."
                title="Contact data"
              />

              <Box
                columnGap={4}
                display="grid"
                gridTemplateColumns={{
                  base: 'repeat(1, minmax(0,1fr))',
                  sm: 'repeat(2, minmax(0,1fr))',
                  md: 'repeat(3, minmax(0,1fr))',
                }}
                paddingTop={4}
                rowGap={4}
              >
                <Box gridColumn={{ base: 1, sm: '1 / span 2' }}>
                  <FormControl
                    isInvalid={errors.contactAddress?.street !== undefined}
                  >
                    <Label htmlFor="street">Street</Label>
                    <Input
                      autoComplete="off"
                      id="street"
                      {...register('contactAddress.street', {
                        required: 'This is required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactAddress?.street &&
                        errors.contactAddress.street.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box gridColumnStart={1}>
                  <FormControl
                    isInvalid={errors.contactAddress?.postalCode !== undefined}
                  >
                    <Label htmlFor="postalCode">Postal code</Label>
                    <Input
                      autoComplete="off"
                      id="postalCode"
                      {...register('contactAddress.postalCode', {
                        required: 'This is required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactAddress?.postalCode &&
                        errors.contactAddress?.postalCode.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <FormControl
                  isInvalid={errors.contactAddress?.city !== undefined}
                >
                  <Label htmlFor="city">City</Label>
                  <Input
                    autoComplete="off"
                    id="city"
                    {...register('contactAddress.city', {
                      required: 'This is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.contactAddress?.city &&
                      errors.contactAddress.city.message}
                  </FormErrorMessage>
                </FormControl>

                <Box gridColumn={{ base: 1, sm: '1 / span 2' }}>
                  <FormControl
                    isInvalid={errors.contactAddress?.country !== undefined}
                  >
                    <Label htmlFor="country">Country</Label>
                    <Select
                      autoComplete="off"
                      id="country"
                      {...register('contactAddress.country', {
                        required: 'This is required',
                      })}
                    >
                      <CountryOptions />
                    </Select>
                    <FormErrorMessage>
                      {errors.contactAddress?.country &&
                        errors.contactAddress.country.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box gridColumn={{ base: 1, sm: '1 / span 2' }}>
                  <FormControl
                    isInvalid={errors.contactAddress?.phone !== undefined}
                  >
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      autoComplete="off"
                      id="phone"
                      type="tel"
                      {...register('contactAddress.phone', {
                        required: 'This is required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactAddress?.phone &&
                        errors.contactAddress.phone.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box gridColumn={{ base: 1, sm: '1 / span 2' }}>
                  <FormControl
                    isInvalid={errors.contactAddress?.mail !== undefined}
                  >
                    <Label htmlFor="email">Email</Label>
                    <Input
                      autoComplete="off"
                      id="email"
                      {...register('contactAddress.mail', {
                        required: 'This is required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactAddress?.mail &&
                        errors.contactAddress.mail.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box>
              <SectionHeader title="Billing information" />
              <Box>
                <Checkbox
                  colorScheme="brand"
                  {...register('isSameInvoiceAddress')}
                >
                  Same as contact information
                </Checkbox>

                {!watch('isSameInvoiceAddress') && (
                  <>
                    <FormControl
                      isInvalid={errors.invoiceAddress?.firstname !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.firstname">
                        Firstname
                      </Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.firstname"
                        {...register('invoiceAddress.firstname', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.firstname &&
                          errors.invoiceAddress.firstname.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.invoiceAddress?.lastname !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.lastname">Lastname</Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.lastname"
                        {...register('invoiceAddress.lastname', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.lastname &&
                          errors.invoiceAddress.lastname.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.invoiceAddress?.street !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.street">Street</Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.street"
                        {...register('invoiceAddress.street', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.street &&
                          errors.invoiceAddress.street.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors.invoiceAddress?.postalCode !== undefined
                      }
                    >
                      <Label htmlFor="invoiceAddress.postalCode">
                        Postal code
                      </Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.postalCode"
                        {...register('invoiceAddress.postalCode', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.postalCode &&
                          errors.invoiceAddress.postalCode.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.invoiceAddress?.city !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.city">City</Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.city"
                        {...register('invoiceAddress.city', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.city &&
                          errors.invoiceAddress.city.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.invoiceAddress?.country !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.country">Country</Label>
                      <Select
                        autoComplete="off"
                        id="invoiceAddress.country"
                        {...register('invoiceAddress.country', {
                          required: 'This is required',
                        })}
                      >
                        <CountryOptions />
                      </Select>
                      <FormErrorMessage>
                        {errors.invoiceAddress?.country &&
                          errors.invoiceAddress.country.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.invoiceAddress?.phone !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.phone">Phone</Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.phone"
                        type="tel"
                        {...register('invoiceAddress.phone', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.phone &&
                          errors.invoiceAddress.phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.invoiceAddress?.mail !== undefined}
                    >
                      <Label htmlFor="invoiceAddress.mail">Email</Label>
                      <Input
                        autoComplete="off"
                        id="invoiceAddress.mail"
                        {...register('invoiceAddress.mail', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.invoiceAddress?.mail &&
                          errors.invoiceAddress.mail.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </Box>
            </Box>

            <Box pb={4}>
              <SectionHeader
                description={`Add additional passenger to this flight. (+${
                  flight ? flight.prices.net + flight.prices.luxuryTax : 0
                } € p.p.)`}
                title="Additional passenger"
              />

              {fields.length > 0 ? (
                <Stack direction="column" spacing={4}>
                  {fields.map((field, index) => (
                    <Box key={field.id}>
                      <Box alignItems="center" display="flex" gap="4" p={2}>
                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.firstname !==
                              undefined
                          }
                        >
                          <Label htmlFor={`firstname_${index}`}>
                            First name
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`firstname_${index}`}
                            {...register(
                              `additionalPassenger.${index}.firstname`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.firstname
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.middlename !==
                              undefined
                          }
                        >
                          <Label htmlFor={`middlename_${index}`}>
                            Middle name
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`middlename_${index}`}
                            {...register(
                              `additionalPassenger.${index}.middlename`
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.middlename
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.lastname !==
                              undefined
                          }
                        >
                          <Label htmlFor={`lastname_${index}`}>Last name</Label>
                          <Input
                            autoComplete="off"
                            id={`lastname_${index}`}
                            {...register(
                              `additionalPassenger.${index}.lastname`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.lastname
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                      <Box display="flex" gap={4}>
                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.birthdate !==
                              undefined
                          }
                        >
                          <Label htmlFor={`birthdate_${index}`}>
                            Date of birth
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`birthdate_${index}`}
                            type="date"
                            {...register(
                              `additionalPassenger.${index}.birthdate`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.birthdate
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.birthplace !==
                              undefined
                          }
                        >
                          <Label htmlFor={`birthplace_${index}`}>
                            Place of birth
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`birthplace_${index}`}
                            {...register(
                              `additionalPassenger.${index}.birthplace`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.birthplace
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.nationality !==
                              undefined
                          }
                        >
                          <Label htmlFor={`nationality_${index}`}>
                            Nationality
                          </Label>
                          <Select
                            autoComplete="off"
                            id={`nationality_${index}`}
                            {...register(
                              `additionalPassenger.${index}.nationality`,
                              {
                                required: 'This is required',
                              }
                            )}
                          >
                            <NationalityOptions />
                          </Select>
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.nationality
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>

                      <Box
                        display="grid"
                        gap={4}
                        gridTemplateColumns={{
                          base: 'repeat(1, minmax(0,1fr))',
                          sm: 'repeat(3, minmax(0,1fr))',
                        }}
                      >
                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.passnumber !==
                              undefined
                          }
                        >
                          <Label htmlFor={`passnumber_${index}`}>
                            Passport No.
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`passnumber_${index}`}
                            {...register(
                              `additionalPassenger.${index}.passnumber`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.passnumber
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]?.passIssueDate !==
                              undefined
                          }
                        >
                          <Label htmlFor={`passIssueDate_${index}`}>
                            Issue date
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`passIssueDate_${index}`}
                            max={dayjs().format('YYYY-MM-DD')}
                            type="date"
                            {...register(
                              `additionalPassenger.${index}.passIssueDate`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.passIssueDate
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.additionalPassenger &&
                            errors.additionalPassenger[index]
                              ?.passExpiryDate !== undefined
                          }
                        >
                          <Label htmlFor={`passExpiryDate_${index}`}>
                            Expiration date
                          </Label>
                          <Input
                            autoComplete="off"
                            id={`passExpiryDate_${index}`}
                            min={dayjs().format('YYYY-MM-DD')}
                            type="date"
                            {...register(
                              `additionalPassenger.${index}.passExpiryDate`,
                              {
                                required: 'This is required',
                              }
                            )}
                          />
                          <FormErrorMessage>
                            {errors.additionalPassenger &&
                              errors.additionalPassenger[index]?.passExpiryDate
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>

                      <Box display="flex" justifyContent="start" py={4}>
                        <Button
                          colorScheme="brand"
                          onClick={() => remove(index)}
                          variant="outline"
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              ) : null}

              <Box>
                <Button
                  colorScheme="brand"
                  isDisabled={fields.length >= 5}
                  onClick={handleAdd}
                  size="sm"
                  variant="outline"
                >
                  Add passenger
                </Button>
              </Box>
            </Box>

            <Box alignItems="center" display="flex" gap={4} marginTop={8}>
              <Button
                colorScheme="brand"
                isDisabled={isSubmitting}
                onClick={handleCancel}
                type="button"
                variant="outline"
              >
                Go back
              </Button>
              <Button
                colorScheme="brand"
                isDisabled={isSubmitting}
                type="submit"
              >
                Continue
              </Button>
              {process.env.NODE_ENV === 'development' && (
                <Button onClick={handlePreFill}>Prefill Data</Button>
              )}
            </Box>
          </Stack>
        </>
      )}
    </form>
  )
}

export default PassengerForm
