import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type BookMutationVariables = Types.Exact<{
  leg: Types.Scalars['Int']
  cardCVC: Types.Scalars['Int']
  cardExpirationDate: Types.Scalars['String']
  cardholder: Types.Scalars['String']
  cardnumber: Types.Scalars['String']
  subtotal: Types.Scalars['Float']
  taxes: Types.Scalars['Float']
  luxuryTaxes: Types.Scalars['Float']
  serviceFee: Types.Scalars['Float']
  total: Types.Scalars['Float']
  passenger:
    | Array<Types.TravellerPassengerInput>
    | Types.TravellerPassengerInput
  contactAddress: Types.TravellerAddressInput
  invoiceAddress: Types.TravellerAddressInput
}>

export type BookMutation = {
  __typename?: 'Mutation'
  bookFlight: { __typename?: 'BookedFlight'; bookingNumber: string }
}

export const BookDocument = gql`
  mutation Book(
    $leg: Int!
    $cardCVC: Int!
    $cardExpirationDate: String!
    $cardholder: String!
    $cardnumber: String!
    $subtotal: Float!
    $taxes: Float!
    $luxuryTaxes: Float!
    $serviceFee: Float!
    $total: Float!
    $passenger: [TravellerPassengerInput!]!
    $contactAddress: TravellerAddressInput!
    $invoiceAddress: TravellerAddressInput!
  ) {
    bookFlight(
      input: {
        leg: $leg
        cardCVC: $cardCVC
        cardExpirationDate: $cardExpirationDate
        cardholder: $cardholder
        cardnumber: $cardnumber
        subtotal: $subtotal
        taxes: $taxes
        luxuryTaxes: $luxuryTaxes
        serviceFee: $serviceFee
        total: $total
        passengers: $passenger
        contactAddress: $contactAddress
        invoiceAddress: $invoiceAddress
      }
    ) {
      bookingNumber
    }
  }
`
export type BookMutationFn = Apollo.MutationFunction<
  BookMutation,
  BookMutationVariables
>

/**
 * __useBookMutation__
 *
 * To run a mutation, you first call `useBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookMutation, { data, loading, error }] = useBookMutation({
 *   variables: {
 *      leg: // value for 'leg'
 *      cardCVC: // value for 'cardCVC'
 *      cardExpirationDate: // value for 'cardExpirationDate'
 *      cardholder: // value for 'cardholder'
 *      cardnumber: // value for 'cardnumber'
 *      subtotal: // value for 'subtotal'
 *      taxes: // value for 'taxes'
 *      luxuryTaxes: // value for 'luxuryTaxes'
 *      serviceFee: // value for 'serviceFee'
 *      total: // value for 'total'
 *      passenger: // value for 'passenger'
 *      contactAddress: // value for 'contactAddress'
 *      invoiceAddress: // value for 'invoiceAddress'
 *   },
 * });
 */
export function useBookMutation(
  baseOptions?: Apollo.MutationHookOptions<BookMutation, BookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BookMutation, BookMutationVariables>(
    BookDocument,
    options
  )
}
export type BookMutationHookResult = ReturnType<typeof useBookMutation>
export type BookMutationResult = Apollo.MutationResult<BookMutation>
export type BookMutationOptions = Apollo.BaseMutationOptions<
  BookMutation,
  BookMutationVariables
>
