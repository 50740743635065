import { Input as ChakraInput } from '@chakra-ui/react'
import React from 'react'

type InputProps = React.ComponentProps<typeof ChakraInput>

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    return <ChakraInput {...props} fontSize={{ sm: 'sm' }} ref={ref} />
  }
)

Input.displayName = 'Input'

export default Input
