import { Icon } from '@chakra-ui/react'
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from 'react-icons/fa'

type CreditCardIconProps = {
  cardType?: string
  size?: 'default' | 'large'
}

const CreditCardIcon: React.FC<CreditCardIconProps> = ({
  cardType,
  size = 'default',
}) => {
  const iconSize = size === 'default' ? 5 : 7

  return (
    <Icon
      as={getIcon(cardType)}
      color={cardType ? 'purple.500' : 'gray.500'}
      h={iconSize}
      w={iconSize}
    />
  )
}

const getIcon = (cardType?: string) => {
  switch (cardType) {
    case 'amex': {
      return FaCcAmex
    }

    case 'dinersclub': {
      return FaCcDinersClub
    }

    case 'discover': {
      return FaCcDiscover
    }

    case 'jcb': {
      return FaCcJcb
    }

    case 'mastercard': {
      return FaCcMastercard
    }

    case 'visa': {
      return FaCcVisa
    }

    default: {
      return FaCreditCard
    }
  }
}

export default CreditCardIcon
