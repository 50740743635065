import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type BookingDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']
}>

export type BookingDetailsQuery = {
  __typename?: 'Query'
  bookedFlights: Array<{
    __typename?: 'BookedFlight'
    bookingNumber: string
    subtotal: number
    taxes: number
    luxuryTaxes: number
    serviceFee: number
    total: number
    isCancelled: boolean
    leg: {
      __typename?: 'FlightLeg'
      departure: {
        __typename?: 'FlightLocationDetails'
        airport: { __typename?: 'Airport'; icao: string; name: string }
        date: { __typename?: 'LegDate'; local: any }
        time: { __typename?: 'LegTime'; local: any }
      }
      destination: {
        __typename?: 'FlightLocationDetails'
        airport: { __typename?: 'Airport'; icao: string; name: string }
        time: { __typename?: 'LegTime'; local: any }
      }
      aircraft: { __typename?: 'Aircraft'; type: string }
    }
    invoiceAddress?: {
      __typename?: 'Address'
      firstname: string
      lastname: string
      street: string
      postalCode: string
      city: string
    } | null
    passenger: Array<{
      __typename?: 'TravellerPassenger'
      firstname: string
      middlename?: string | null
      lastname: string
      passnumber: string
      passExpirationDate: any
    }>
    payment?: {
      __typename?: 'PaymentInformation'
      cardnumber: string
      cardExpirationDate: string
    } | null
  }>
}

export const BookingDetailsDocument = gql`
  query BookingDetails($id: Int!) {
    bookedFlights(filter: { id: { _eq: $id } }) {
      bookingNumber
      leg {
        departure {
          airport {
            icao
            name
          }
          date {
            local
          }
          time {
            local
          }
        }
        destination {
          airport {
            icao
            name
          }
          time {
            local
          }
        }
        aircraft {
          type
        }
      }
      invoiceAddress {
        firstname
        lastname
        street
        postalCode
        city
      }
      passenger {
        firstname
        middlename
        lastname
        passnumber
        passExpirationDate
      }
      payment {
        cardnumber
        cardExpirationDate
      }
      subtotal
      taxes
      luxuryTaxes
      serviceFee
      total
      isCancelled
    }
  }
`

/**
 * __useBookingDetailsQuery__
 *
 * To run a query within a React component, call `useBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookingDetailsQuery,
    BookingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(
    BookingDetailsDocument,
    options
  )
}
export function useBookingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingDetailsQuery,
    BookingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(
    BookingDetailsDocument,
    options
  )
}
export type BookingDetailsQueryHookResult = ReturnType<
  typeof useBookingDetailsQuery
>
export type BookingDetailsLazyQueryHookResult = ReturnType<
  typeof useBookingDetailsLazyQuery
>
export type BookingDetailsQueryResult = Apollo.QueryResult<
  BookingDetailsQuery,
  BookingDetailsQueryVariables
>
