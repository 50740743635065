import { ApolloProvider } from '@apollo/client'
import { PropsWithChildren } from 'react'

import { createClient } from '../../utils/client'
import { useAuth } from '../AuthProvider'
import { useUser } from '../UserProvider'

const AuthorizedApolloProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { auth } = useAuth()
  const { user } = useUser()

  const client = createClient(() =>
    auth?.isPAD ? auth.token : user?.token ?? 'Invalid'
  )

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
