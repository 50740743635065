import {
  Box,
  Heading,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { FaSuitcaseRolling } from 'react-icons/fa'

import { toDate } from '../../utils/format'
import SectionHeader from '../SectionHeader'

type FlightDetailsProps = {
  departure: {
    icao: string
    name: string
  }
  destination: {
    icao: string
    name: string
  }
  date: string
  time?: string
  arrivalTime?: string
}

const FlightDetails: React.FC<FlightDetailsProps> = ({
  arrivalTime,
  date,
  departure,
  destination,
  time,
}) => {
  return (
    <Box bgColor="gray.100" p={4} rounded="xl">
      <SectionHeader
        description="Information about your flight"
        title="Flight details"
      />
      <Box display="flex" flexDirection="column" gap={4} py={4}>
        <Box display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Departure
            </Text>
            <Box display="flex" gap={2}>
              {departure.name} <Text color="gray.500">({departure.icao})</Text>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Destination
            </Text>
            <Box display="flex" gap={2}>
              {destination.name}{' '}
              <Text color="gray.500">({destination.icao})</Text>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Aircraft type
            </Text>
            <Box display="flex" gap={2}>
              Phenom 300
            </Box>
          </Box>
        </Box>

        <Box display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Date
            </Text>
            <Text display="flex" gap={2}>
              {toDate(date)}
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Time
            </Text>
            <Text display="flex" gap={2}>
              {time} - {arrivalTime}
            </Text>
          </Box>
        </Box>
      </Box>

      <VStack alignItems="stretch" marginTop={4} spacing={0}>
        <Box
          bgColor="blue.50"
          borderLeftWidth={4}
          borderLeftColor="blue.400"
          p={4}
        >
          <Box display="flex" gap={3}>
            <Box flexShrink={0}>
              <Icon as={FaSuitcaseRolling} color="blue.400" w={5} h={5} />
            </Box>
            <Box color="blue.700" fontSize="sm">
              <Heading
                as="h3"
                color="blue.800"
                fontSize="sm"
                fontWeight="medium"
              >
                Baggage allowance per passenger:
              </Heading>
              <UnorderedList marginTop={2}>
                <ListItem>1 item of baggage, up to 20kg</ListItem>
                <ListItem>
                  1 item of carry-on baggage, up to 8kg (55x40x20cm)
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
        </Box>

        {/* <Box
          bgColor="yellow.50"
          borderLeftWidth={4}
          borderLeftColor="yellow.400"
          p={4}
        >
          <Box display="flex" gap={3}>
            <WarningIcon w={5} h={5} color="yellow.400" />
            <Text color="yellow.700" fontSize="sm">
              Be aware, that this flight might not take place at all.
            </Text>
          </Box>
        </Box> */}
      </VStack>
    </Box>
  )
}

export default FlightDetails
