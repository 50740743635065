import { Box, Button, Heading, Link, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ErrorLogo } from './error.svg'

const Error: React.FC = () => {
  const navigate = useNavigate()

  return (
    <VStack spacing={6}>
      <Box as={ErrorLogo} color="brand.500" h={64} w={64} />
      <VStack maxWidth="md" spacing={2}>
        <Heading as="h3" size="md">
          An error occurred
        </Heading>
        <Text color="gray.500" textAlign="justify">
          Sorry, but we were unabled to get the requested page. This might be a
          temporary problem. If you need assistance, please contact us:{' '}
          <Link color="brand.500" fontWeight="medium" href="tel:00492955748271">
            +49 2955 74 82 71
          </Link>
        </Text>
      </VStack>
      <Button colorScheme="brand" variant="solid" onClick={() => navigate(0)}>
        Try again
      </Button>
    </VStack>
  )
}

export default Error
