import { Box, CircularProgress, Progress } from '@chakra-ui/react'

const Loader: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" py={16}>
      <CircularProgress
        aria-label="Loading"
        isIndeterminate
        color="brand.500"
      />
    </Box>
  )
  return <Progress colorScheme="brand" isIndeterminate size="sm" />
}

export default Loader
