import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { baseURL } from '../config'

const cache = new InMemoryCache()
const link = createUploadLink({
  uri: `${baseURL}/graphql.php`,
})

const createAuthMiddleware = (getToken: () => string): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      headers: {
        auth: 'Traveller',
        bearer: getToken(),
        mode: process.env.REACT_APP_MODE ?? 'development',
      },
    }))
    return forward(operation)
  })
}

export const createClient = (
  getToken: () => string
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: cache,
    link: from([createAuthMiddleware(getToken), link]),
  })
}
