export const groupBy = <T, K extends keyof T>(
  items: T[],
  by: (item: T) => K
) => {
  return items.reduce((acc, cur) => {
    const key = by(cur)
    acc[key] = acc[key] || []
    acc[key].push(cur)
    return acc
  }, {} as Record<K, T[]>)
}
