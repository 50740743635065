import { FormLabel } from '@chakra-ui/react'
import React from 'react'

type LabelProps = React.ComponentProps<typeof FormLabel>

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ ...props }, ref) => {
    return (
      <FormLabel
        {...props}
        color="gray.700"
        fontSize="sm"
        fontWeight="medium"
        ref={ref}
      />
    )
  }
)

Label.displayName = 'Label'

export default Label
