import { useState } from 'react'

import BookingConfirmation from '../BookingConfirmation'
import FlightList from '../FlightList'
import PassengerForm from '../PassengerForm'
import PaymentForm from '../PaymentForm'

type Pages = 'LIST' | 'PASSENGER' | 'PAYMENT' | 'CONFIRMATION'

export type FlightInfo = {
  id: number
  departure: {
    icao: string
    name: string
  }
  destination: {
    icao: string
    name: string
  }
  aircraft: string
  date: string
  departureTime: string
  arrivalTime: string
  price: {
    luxuryTax: number
    net: number
    serviceFee: number
    taxes: number
  }
}

export type FlightPrice = {
  luxuryTax: number
  serviceFee: number
  subtotal: number
  taxes: number
  total: number
}

export type PassengerInfo = {
  firstname: string
  middlename?: string
  lastname: string
  birthdate: string
  birthplace: string
  nationality: string
  passnumber: string
  passIssueDate: string
  passExpiryDate: string
}

export type AddressInfo = {
  firstname: string
  lastname: string
  street: string
  postalCode: string
  city: string
  country: string
  phone: string
  mail: string
}

export type PassengerPageInfo = {
  flightInfo: FlightInfo
  passenger: PassengerInfo
  additionalPassenger: PassengerInfo[]
  contactAddress: AddressInfo
  invoiceAddress: AddressInfo
  isSameInvoiceAddress: boolean
}

export type PaymentInfo = {
  cardholder: string
  cardnumber: string
  cardtype: string
  expirationDate: string
  cvc: number
}

export type PaymentPageInfo = {
  payment: PaymentInfo
  bookingNumber: string
}

const Flights: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<Pages>('LIST')
  const [selectedFlight, setSelectedFlight] = useState<number | null>(null)
  const [passengerPageInfo, setPassengerPageInfo] =
    useState<PassengerPageInfo | null>(null)
  const [paymentPageInfo, setPaymentPageInfo] =
    useState<PaymentPageInfo | null>(null)
  const [priceInfo, setPriceInfo] = useState<FlightPrice | null>(null)

  const handleSelectFlight = (flight: number) => {
    setSelectedFlight(flight)
    setCurrentPage('PASSENGER')
  }

  const handlePassengerPageBack = () => {
    setPassengerPageInfo(null)
    setCurrentPage('LIST')
  }

  const handlePassengerPageContinue = (pageInfo: PassengerPageInfo) => {
    const { luxuryTax, net, serviceFee, taxes } = pageInfo.flightInfo.price

    const numberOfPassenger = pageInfo.additionalPassenger.length + 1
    const subtotal = numberOfPassenger * (net + luxuryTax)
    const tax = (subtotal * taxes) / 100
    const serviceFees = ((subtotal + tax) * serviceFee) / 100
    const total = subtotal + tax + serviceFees

    setPassengerPageInfo(pageInfo)
    setPriceInfo({
      luxuryTax: numberOfPassenger * luxuryTax,
      serviceFee: serviceFees,
      subtotal: numberOfPassenger * net,
      taxes: tax,
      total: total,
    })
    setCurrentPage('PAYMENT')
  }

  const handlePaymentPageBack = () => {
    setPaymentPageInfo(null)
    setCurrentPage('PASSENGER')
  }

  const handlePaymentPageContinue = (pageInfo: PaymentPageInfo) => {
    setPaymentPageInfo(pageInfo)
    setCurrentPage('CONFIRMATION')
  }

  switch (currentPage) {
    case 'CONFIRMATION': {
      if (!passengerPageInfo || !paymentPageInfo || !priceInfo) {
        return <FlightList onSelectFlight={handleSelectFlight} />
      }

      return (
        <BookingConfirmation
          bookingNumber={paymentPageInfo.bookingNumber}
          flight={passengerPageInfo.flightInfo}
          invoiceAddress={passengerPageInfo.invoiceAddress}
          passengers={[
            passengerPageInfo.passenger,
            ...passengerPageInfo.additionalPassenger,
          ]}
          payment={paymentPageInfo.payment}
          price={priceInfo}
        />
      )
    }

    case 'LIST':
    default: {
      return <FlightList onSelectFlight={handleSelectFlight} />
    }

    case 'PASSENGER': {
      if (!selectedFlight) {
        return <FlightList onSelectFlight={handleSelectFlight} />
      }

      return (
        <PassengerForm
          defaultValues={
            passengerPageInfo
              ? {
                  ...passengerPageInfo,
                }
              : undefined
          }
          onBack={handlePassengerPageBack}
          onContinue={handlePassengerPageContinue}
          selectedFlight={selectedFlight}
        />
      )
    }

    case 'PAYMENT': {
      if (!passengerPageInfo || !priceInfo) {
        return <FlightList onSelectFlight={handleSelectFlight} />
      }

      return (
        <PaymentForm
          contactAddress={passengerPageInfo.contactAddress}
          flight={passengerPageInfo.flightInfo}
          invoiceAddress={passengerPageInfo.invoiceAddress}
          onBack={handlePaymentPageBack}
          onContinue={handlePaymentPageContinue}
          passengers={[
            passengerPageInfo.passenger,
            ...passengerPageInfo.additionalPassenger,
          ]}
          price={priceInfo}
        />
      )
    }
  }
}

export default Flights
