const NationalityOptions: React.FC = () => {
  return (
    <>
      <optgroup label="A">
        <option value="Afghan">Afghan</option>
        <option value="Albanian">Albanian</option>
        <option value="Algerian">Algerian</option>
        <option value="American">American</option>
        <option value="Andorran">Andorran</option>
        <option value="Angolan">Angolan</option>
        <option value="Antiguans">Antiguans</option>
        <option value="Argentinean">Argentinean</option>
        <option value="Armenian">Armenian</option>
        <option value="Australian">Australian</option>
        <option value="Austrian">Austrian</option>
        <option value="Azerbaijani">Azerbaijani</option>
      </optgroup>

      <optgroup label="B">
        <option value="Bahamian">Bahamian</option>
        <option value="Bahraini">Bahraini</option>
        <option value="Bangladeshi">Bangladeshi</option>
        <option value="Barbadian">Barbadian</option>
        <option value="Barbudans">Barbudans</option>
        <option value="Batswana">Batswana</option>
        <option value="Belarusian">Belarusian</option>
        <option value="Belgian">Belgian</option>
        <option value="Belizean">Belizean</option>
        <option value="Beninese">Beninese</option>
        <option value="Bhutanese">Bhutanese</option>
        <option value="Bolivian">Bolivian</option>
        <option value="Bosnian">Bosnian</option>
        <option value="Brazilian">Brazilian</option>
        <option value="British">British</option>
        <option value="Bruneian">Bruneian</option>
        <option value="Bulgarian">Bulgarian</option>
        <option value="Burkinabe">Burkinabe</option>
        <option value="Burmese">Burmese</option>
        <option value="Burundian">Burundian</option>
      </optgroup>

      <optgroup label="C">
        <option value="Cambodian">Cambodian</option>
        <option value="Cameroonian">Cameroonian</option>
        <option value="Canadian">Canadian</option>
        <option value="Cape Verdean">Cape Verdean</option>
        <option value="Central African">Central African</option>
        <option value="Chadian">Chadian</option>
        <option value="Chilean">Chilean</option>
        <option value="Chinese">Chinese</option>
        <option value="Colombian">Colombian</option>
        <option value="Comoran">Comoran</option>
        <option value="Congolese">Congolese</option>
        <option value="Costa Rican">Costa Rican</option>
        <option value="Croatian">Croatian</option>
        <option value="Cuban">Cuban</option>
        <option value="Cypriot">Cypriot</option>
        <option value="Czech">Czech</option>
      </optgroup>

      <optgroup label="D">
        <option value="Danish">Danish</option>
        <option value="Djibouti">Djibouti</option>
        <option value="Dominican">Dominican</option>
        <option value="Dutch">Dutch</option>
      </optgroup>

      <optgroup label="E">
        <option value="East Timorese">East Timorese</option>
        <option value="Ecuadorean">Ecuadorean</option>
        <option value="Egyptian">Egyptian</option>
        <option value="Emirian">Emirian</option>
        <option value="Equatorial Guinean">Equatorial Guinean</option>
        <option value="Eritrean">Eritrean</option>
        <option value="Estonian">Estonian</option>
        <option value="Ethiopian">Ethiopian</option>
      </optgroup>

      <optgroup label="F">
        <option value="Fijian">Fijian</option>
        <option value="Filipino">Filipino</option>
        <option value="Finnish">Finnish</option>
        <option value="French">French</option>
      </optgroup>

      <optgroup label="G">
        <option value="Gabonese">Gabonese</option>
        <option value="Gambian">Gambian</option>
        <option value="Georgian">Georgian</option>
        <option value="German">German</option>
        <option value="Ghanaian">Ghanaian</option>
        <option value="Greek">Greek</option>
        <option value="Grenadian">Grenadian</option>
        <option value="Guatemalan">Guatemalan</option>
        <option value="Guinea-Bissauan">Guinea-Bissauan</option>
        <option value="Guinean">Guinean</option>
        <option value="Guyanese">Guyanese</option>
      </optgroup>

      <optgroup label="H">
        <option value="Haitian">Haitian</option>
        <option value="Herzegovinian">Herzegovinian</option>
        <option value="Honduran">Honduran</option>
        <option value="Hungarian">Hungarian</option>
      </optgroup>

      <optgroup label="I">
        <option value="Icelander">Icelander</option>
        <option value="Indian">Indian</option>
        <option value="Indonesian">Indonesian</option>
        <option value="Iranian">Iranian</option>
        <option value="Iraqi">Iraqi</option>
        <option value="Irish">Irish</option>
        <option value="Israeli">Israeli</option>
        <option value="Italian">Italian</option>
        <option value="Ivorian">Ivorian</option>
      </optgroup>

      <optgroup label="J">
        <option value="Jamaican">Jamaican</option>
        <option value="Japanese">Japanese</option>
        <option value="Jordanian">Jordanian</option>
      </optgroup>

      <optgroup label="K">
        <option value="Kazakhstani">Kazakhstani</option>
        <option value="Kenyan">Kenyan</option>
        <option value="Kittian and Nevisian">Kittian and Nevisian</option>
        <option value="Kuwaiti">Kuwaiti</option>
        <option value="Kyrgyz">Kyrgyz</option>
      </optgroup>

      <optgroup label="L">
        <option value="Laotian">Laotian</option>
        <option value="Latvian">Latvian</option>
        <option value="Lebanese">Lebanese</option>
        <option value="Liberian">Liberian</option>
        <option value="Libyan">Libyan</option>
        <option value="Liechtensteiner">Liechtensteiner</option>
        <option value="Lithuanian">Lithuanian</option>
        <option value="Luxembourger">Luxembourger</option>
      </optgroup>

      <optgroup label="M">
        <option value="Macedonian">Macedonian</option>
        <option value="Malagasy">Malagasy</option>
        <option value="Malawian">Malawian</option>
        <option value="Malaysian">Malaysian</option>
        <option value="Maldivan">Maldivan</option>
        <option value="Malian">Malian</option>
        <option value="Maltese">Maltese</option>
        <option value="Marshallese">Marshallese</option>
        <option value="Mauritanian">Mauritanian</option>
        <option value="Mauritian">Mauritian</option>
        <option value="Mexican">Mexican</option>
        <option value="Micronesian">Micronesian</option>
        <option value="Moldovan">Moldovan</option>
        <option value="Monacan">Monacan</option>
        <option value="Mongolian">Mongolian</option>
        <option value="Moroccan">Moroccan</option>
        <option value="Mosotho">Mosotho</option>
        <option value="Motswana">Motswana</option>
        <option value="Mozambican">Mozambican</option>
      </optgroup>

      <optgroup label="N">
        <option value="Namibian">Namibian</option>
        <option value="Nauruan">Nauruan</option>
        <option value="Nepalese">Nepalese</option>
        <option value="New Zealander">New Zealander</option>
        <option value="Ni-Vanuatu">Ni-Vanuatu</option>
        <option value="Nicaraguan">Nicaraguan</option>
        <option value="Nigerien">Nigerien</option>
        <option value="North Korean">North Korean</option>
        <option value="Northern Irish">Northern Irish</option>
        <option value="Norwegian">Norwegian</option>
      </optgroup>

      <optgroup label="O">
        <option value="Omani">Omani</option>
      </optgroup>

      <optgroup label="P">
        <option value="Pakistani">Pakistani</option>
        <option value="Palauan">Palauan</option>
        <option value="Panamanian">Panamanian</option>
        <option value="Papua New Guinean">Papua New Guinean</option>
        <option value="Paraguayan">Paraguayan</option>
        <option value="Peruvian">Peruvian</option>
        <option value="Polish">Polish</option>
        <option value="Portuguese">Portuguese</option>
      </optgroup>

      <optgroup label="Q">
        <option value="Qatari">Qatari</option>
      </optgroup>

      <optgroup label="R">
        <option value="Romanian">Romanian</option>
        <option value="Russian">Russian</option>
        <option value="Rwandan">Rwandan</option>
      </optgroup>

      <optgroup label="S">
        <option value="Salvadoran">Salvadorean</option>
        <option value="Samoan">Samoan</option>
        <option value="Sammarinesee">Sammarinese</option>
        <option value="Sao Tomean">Sao Tomean</option>
        <option value="Saudi Arabian">Saudi Arabian</option>
        <option value="Scottish">Scottish</option>
        <option value="Senegalese">Senegalese</option>
        <option value="Serbian">Serbian</option>
        <option value="Citizen of Seychelles">Citizen of Seychelles</option>
        <option value="Sierra Leonean">Sierra Leonean</option>
        <option value="Singaporean">Singaporean</option>
        <option value="Slovak">Slovak</option>
        <option value="Slovenian">Slovenian</option>
        <option value="Solomon Islander">Solomon Islander</option>
        <option value="Somali">Somali</option>
        <option value="South African">South African</option>
        <option value="South Korean">South Korean</option>
        <option value="South Sudanese">South Sudanese</option>
        <option value="Spanish">Spanish</option>
        <option value="Sri Lankan">Sri Lankan</option>
        <option value="St Helenian">St Helenian</option>
        <option value="St Lucian">St Lucian</option>
        <option value="Stateless">Stateless</option>
        <option value="Sudanese">Sudanese</option>
        <option value="Surinamese">Surinamese</option>
        <option value="Swazi">Swazi</option>
        <option value="Swedish">Swedish</option>
        <option value="Swiss">Swiss</option>
        <option value="Syrian">Syrian</option>
      </optgroup>

      <optgroup label="T">
        <option value="Taiwanese">Taiwanese</option>
        <option value="Tajik">Tajik</option>
        <option value="Tanzanian">Tanzanian</option>
        <option value="Thai">Thai</option>
        <option value="Togolese">Togolese</option>
        <option value="Tongan">Tongan</option>
        <option value="Trinidadian">Trinidadian</option>
        <option value="Tristanian">Tristanian</option>
        <option value="Tunisian">Tunisian</option>
        <option value="Turkish">Turkish</option>
        <option value="Turkmen">Turkmen</option>
        <option value="Turks and Caicos Islander">
          Turks and Caicos Islander
        </option>
        <option value="Tuvaluan">Tuvaluan</option>
      </optgroup>

      <optgroup label="U">
        <option value="Ugandan">Ugandan</option>
        <option value="Ukrainian">Ukrainian</option>
        <option value="Uruguayan">Uruguayan</option>
        <option value="Uzbek">Uzbek</option>
      </optgroup>

      <optgroup label="V">
        <option value="Vatican citizen">Vatican citizen</option>
        <option value="Citizen of Vanuatu">Citizen of Vanuatu</option>
        <option value="Venezuelan">Venezuelan</option>
        <option value="Vietnamese">Vietnamese</option>
        <option value="Vincentian	">Vincentian</option>
      </optgroup>

      <optgroup label="W">
        <option value="Wallisian">Wallisian</option>
        <option value="Welsh">Welsh</option>
      </optgroup>

      <optgroup label="Y">
        <option value="Yemeni">Yemeni</option>
      </optgroup>

      <optgroup label="Z">
        <option value="Zambian">Zambian</option>
        <option value="Zimbabwean">Zimbabwean</option>
      </optgroup>
    </>
  )
}

export default NationalityOptions
