import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'

import { useAuth } from '../AuthProvider'
import BookingDetails from '../BookingDetails'
import Bookings from '../Bookings'
import CompanySelector from '../CompanySelector'
import Disclaimer from '../Disclaimer'
import { useDisclaimer } from '../DisclaimerProvider'
import Error from '../Error'
import Flights from '../Flights'
import Layout from '../Layout'
import Login from '../Login'
import RequireAuth from '../RequireAuth'
import RequireUser from '../RequireUser'
import { useUser } from '../UserProvider'

const Sample = () => {
  const { auth } = useAuth()
  const { isAccepted } = useDisclaimer()
  const { user } = useUser()
  const location = useLocation()

  if (!auth?.isPAD) {
    if (!isAccepted) {
      return <Navigate to="/agreement" state={{ from: location }} replace />
    }

    if (!user) {
      return <Navigate to="/company" state={{ from: location }} replace />
    }
  }

  return <Outlet />
}

const Routes: React.FC = () => {
  return useRoutes([
    { element: <Login />, path: 'login' },
    {
      element: <RequireAuth />,
      children: [
        {
          element: <Layout />,
          children: [
            {
              element: <Sample />,
              children: [
                {
                  element: <RequireUser />,
                  children: [{ element: <Flights />, path: 'flights' }],
                },
                { element: <Bookings />, path: 'bookings' },
                { element: <BookingDetails />, path: 'bookings/:id' },
              ],
            },
            { element: <Disclaimer />, path: 'agreement' },
            { element: <CompanySelector />, path: 'company' },
            { element: <Error />, path: '*' },
          ],
        },
      ],
    },
  ])
}

export default Routes
