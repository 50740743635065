import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type CancelFlightMutationVariables = Types.Exact<{
  flight: Types.Scalars['Int']
}>

export type CancelFlightMutation = {
  __typename?: 'Mutation'
  cancelFlightByTraveller: boolean
}

export const CancelFlightDocument = gql`
  mutation CancelFlight($flight: Int!) {
    cancelFlightByTraveller(input: { flight: $flight })
  }
`
export type CancelFlightMutationFn = Apollo.MutationFunction<
  CancelFlightMutation,
  CancelFlightMutationVariables
>

/**
 * __useCancelFlightMutation__
 *
 * To run a mutation, you first call `useCancelFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFlightMutation, { data, loading, error }] = useCancelFlightMutation({
 *   variables: {
 *      flight: // value for 'flight'
 *   },
 * });
 */
export function useCancelFlightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelFlightMutation,
    CancelFlightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelFlightMutation,
    CancelFlightMutationVariables
  >(CancelFlightDocument, options)
}
export type CancelFlightMutationHookResult = ReturnType<
  typeof useCancelFlightMutation
>
export type CancelFlightMutationResult =
  Apollo.MutationResult<CancelFlightMutation>
export type CancelFlightMutationOptions = Apollo.BaseMutationOptions<
  CancelFlightMutation,
  CancelFlightMutationVariables
>
