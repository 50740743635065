import { Box, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'

type Step = {
  name: string
  status: 'upcoming' | 'current' | 'complete'
}

type StepsProps = {
  steps: Step[]
}

const Steps: React.FC<StepsProps> = ({ steps }) => {
  return (
    <Box as="nav">
      <List
        display={{ md: 'flex' }}
        gap={{ md: 8 }}
        spacing={{ base: 4, md: 0 }}
      >
        {steps.map((step, index) => (
          <ListItem key={step.name} flexGrow={{ md: 1 }}>
            {step.status === 'complete' ? (
              <Box
                borderColor="brand.600"
                borderLeftWidth={{ base: 4, md: 0 }}
                borderTopWidth={{ base: 0, md: 4 }}
                display="flex"
                flexDirection="column"
                pl={4}
                py={2}
              >
                <Text color="brand.600" fontSize="sm" fontWeight="medium">
                  Step {index + 1}
                </Text>
                <Text fontSize="sm" fontWeight="medium">
                  {step.name}
                </Text>
              </Box>
            ) : step.status === 'current' ? (
              <Box
                aria-current="step"
                borderColor="brand.600"
                borderLeftWidth={{ base: 4, md: 0 }}
                borderTopWidth={{ base: 0, md: 4 }}
                display="flex"
                flexDirection="column"
                pl={4}
                py={2}
              >
                <Text color="brand.600" fontSize="sm" fontWeight="medium">
                  Step {index + 1}
                </Text>
                <Text fontSize="sm" fontWeight="medium">
                  {step.name}
                </Text>
              </Box>
            ) : (
              <Box
                borderColor="gray.200"
                borderLeftWidth={{ base: 4, md: 0 }}
                borderTopWidth={{ base: 0, md: 4 }}
                display="flex"
                flexDirection="column"
                pl={4}
                py={2}
              >
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  Step {index + 1}
                </Text>
                <Text fontSize="sm" fontWeight="medium">
                  {step.name}
                </Text>
              </Box>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default Steps
