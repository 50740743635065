import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type PassengerFormQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']
}>

export type PassengerFormQuery = {
  __typename?: 'Query'
  legs: Array<{
    __typename?: 'FlightLeg'
    departure: {
      __typename?: 'FlightLocationDetails'
      airport: { __typename?: 'Airport'; icao: string; name: string }
      date: { __typename?: 'LegDate'; local: any }
      time: { __typename?: 'LegTime'; local: any }
    }
    destination: {
      __typename?: 'FlightLocationDetails'
      airport: { __typename?: 'Airport'; icao: string; name: string }
      time: { __typename?: 'LegTime'; local: any }
    }
    aircraft: { __typename?: 'Aircraft'; type: string }
    prices: {
      __typename?: 'LegPrice'
      luxuryTax: number
      net: number
      serviceFee: number
      taxes: number
    }
  }>
}

export const PassengerFormDocument = gql`
  query PassengerForm($id: Int!) {
    legs(filter: { id: { _eq: $id } }) {
      departure {
        airport {
          icao
          name
        }
        date {
          local
        }
        time {
          local
        }
      }
      destination {
        airport {
          icao
          name
        }
        time {
          local
        }
      }
      aircraft {
        type
      }
      prices {
        luxuryTax
        net
        serviceFee
        taxes
      }
    }
  }
`

/**
 * __usePassengerFormQuery__
 *
 * To run a query within a React component, call `usePassengerFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePassengerFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePassengerFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePassengerFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    PassengerFormQuery,
    PassengerFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PassengerFormQuery, PassengerFormQueryVariables>(
    PassengerFormDocument,
    options
  )
}
export function usePassengerFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PassengerFormQuery,
    PassengerFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PassengerFormQuery, PassengerFormQueryVariables>(
    PassengerFormDocument,
    options
  )
}
export type PassengerFormQueryHookResult = ReturnType<
  typeof usePassengerFormQuery
>
export type PassengerFormLazyQueryHookResult = ReturnType<
  typeof usePassengerFormLazyQuery
>
export type PassengerFormQueryResult = Apollo.QueryResult<
  PassengerFormQuery,
  PassengerFormQueryVariables
>
