import { Box, Button, Link } from '@chakra-ui/react'
import { PropsWithChildren, useEffect } from 'react'
import {
  NavLink,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom'

import { useAuth } from '../AuthProvider'
import { useDisclaimer } from '../DisclaimerProvider'
import { useUser } from '../UserProvider'
import { ReactComponent as Logo } from './logo.svg'

const Layout: React.FC = () => {
  const { auth, signout } = useAuth()
  const { isAccepted } = useDisclaimer()
  const { user } = useUser()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(auth?.isPAD ? '/bookings' : '/flights', { replace: true })
    }
  }, [auth, location, navigate])

  return (
    <>
      <Box
        alignItems="center"
        as="nav"
        borderBottomColor="gray.200"
        borderBottomWidth={1}
        display="flex"
        justifyContent="space-between"
        px={4}
      >
        <Box display="flex" gap={8} height={16}>
          <Box alignItems="center" display="flex">
            <Link href="https://www.padaviation.com" target="_blank">
              <Box as={Logo} color="blackAlpha.700" height={10} width="full" />
            </Link>
          </Box>
          <Box display="flex" gap={8}>
            {isAccepted && user && <MenuEntry to="/flights">Flights</MenuEntry>}
            {((isAccepted && user) || auth?.isPAD) && (
              <MenuEntry to="/bookings">Booked</MenuEntry>
            )}
          </Box>
        </Box>
        <Button
          colorScheme="brand"
          onClick={signout}
          type="button"
          variant="ghost"
        >
          Logout
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" p={{ base: 4, lg: 6 }}>
        <Box maxWidth="container.xl" width="full">
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

type MenuEntryProps = {
  to: string
}

const MenuEntry: React.FC<PropsWithChildren<MenuEntryProps>> = ({
  children,
  to,
}) => {
  const isActive = useMatch({ path: to, end: false })

  return (
    <Box
      alignItems="center"
      as={NavLink}
      borderBottomColor="purple"
      borderBottomWidth={isActive ? 2 : undefined}
      display="inline-flex"
      fontWeight="medium"
      pt={1}
      px={1}
      to={to}
    >
      {children}
    </Box>
  )
}

export default Layout
