import { ChakraProvider, extendTheme, theme } from '@chakra-ui/react'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import AuthorizedApolloProvider from '../AuthorizedApolloProvider'
import AuthProvider from '../AuthProvider'
import DisclaimerProvider from '../DisclaimerProvider'
import Routes from '../Routes'
import UserProvider from '../UserProvider'

const customTheme = extendTheme({
  colors: {
    brand: theme.colors.purple,
  },
  styles: {
    global: (props: Record<string, unknown>) => ({
      body: {
        backgroundColor: props.colorMode === 'dark' ? 'gray.900' : 'gray.50',
      },
    }),
  },
})

const App: React.FC = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <BrowserRouter>
        <AuthProvider>
          <DisclaimerProvider>
            <UserProvider>
              <AuthorizedApolloProvider>
                <Routes />
              </AuthorizedApolloProvider>
            </UserProvider>
          </DisclaimerProvider>
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  )
}

export default App
