import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  Icon,
  Link,
  ListItem,
  StackDivider,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { FaSuitcaseRolling } from 'react-icons/fa'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ReactTimeago from 'react-timeago'

import { flightprice } from '../../utils/config'
import { toCurrency, toDate } from '../../utils/format'
import CancellationInformation from '../CancellationInformation'
import CreditCardIcon from '../CreditCardIcon'
import Loader from '../Loader'
import { ReactComponent as Logo } from './airport.svg'
import { ReactComponent as ErrorLogo } from './error.svg'
import { useCancelFlightMutation } from './mutation.generated'
import { useBookingDetailsQuery } from './query.generated'

const BookingDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const toast = useToast()
  const navigate = useNavigate()

  const { data, loading, error, refetch } = useBookingDetailsQuery({
    fetchPolicy: 'network-only',
    variables: { id: +(id || 0) },
  })
  const [cancelFlight, { loading: cancelLoading }] = useCancelFlightMutation()

  const handleCancelFlight = async () => {
    const { data } = await cancelFlight({
      variables: { flight: +(id ?? 0) },
    })
    if (data?.cancelFlightByTraveller) {
      toast({
        description: 'Your flight has been cancelled succesfully',
        status: 'success',
        title: 'Flight cancelled',
      })
      navigate('/bookings')
    } else {
      toast({
        description:
          'Sorry, your flight could not be cancelled. Please try again. If this error still occurs, please contact us directly per phone',
        status: 'error',
        title: 'Error occurred',
      })
    }
  }

  const handleRetry = () => {
    refetch()
  }

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return (
      <VStack spacing={6}>
        <Box as={ErrorLogo} color="brand.500" h={64} w={64} />
        <VStack maxWidth="md" spacing={2}>
          <Heading as="h3" size="md">
            An error occurred
          </Heading>
          <Text color="gray.500" textAlign="justify">
            Sorry, but we were unabled to get your flight data. This might be a
            temporary problem. If you need assistance, please contact us:{' '}
            <Link
              color="brand.500"
              fontWeight="medium"
              href="tel:00492955748271"
            >
              +49 2955 74 82 71
            </Link>
          </Text>
        </VStack>
        <Button colorScheme="brand" variant="solid" onClick={handleRetry}>
          Try again
        </Button>
      </VStack>
    )
  }

  const booking = data.bookedFlights[0]

  return (
    <>
      <div>
        <Box display="flex" justifyContent="center" py={12}>
          <Box as={Logo} color="brand.500" h={64} w={64} />
        </Box>
        <Box display="grid" gap={2} justifyItems="center">
          <Text fontSize="xl" fontWeight="bold">
            Booking number
          </Text>
          <Text color="purple.500" fontSize="xl" fontWeight="bold">
            {booking.bookingNumber}
          </Text>
        </Box>
        {booking.isCancelled && (
          <Box pt={4}>
            <Box
              bgColor="yellow.50"
              borderLeftWidth={4}
              borderLeftColor="yellow.400"
              p={4}
            >
              <Box display="flex" gap={3}>
                <Box flexShrink={0}>
                  <Icon as={FaSuitcaseRolling} color="yellow.400" w={5} h={5} />
                </Box>
                <Box color="yellow.700" fontSize="sm">
                  <Heading
                    as="h3"
                    color="yellow.800"
                    fontSize="sm"
                    fontWeight="medium"
                  >
                    Flight has been cancelled
                  </Heading>
                  <Text mt={2}>
                    We are sorry, but we had to cancel your flight. You will not
                    be charged for your booking at all.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Divider my={4} />
        <Box py={4}>
          <Box>
            <Heading as="h2" size="sm">
              Flight summary
            </Heading>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            rowGap={4}
            py={5}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Departure
              </Text>
              <Box display="flex" fontSize="sm" gap={2}>
                {booking.leg.departure.airport.name}{' '}
                <Text color="gray.500">
                  ({booking.leg.departure.airport.icao})
                </Text>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Destination
              </Text>
              <Box display="flex" fontSize="sm" gap={2}>
                {booking.leg.destination.airport.name}{' '}
                <Text color="gray.500">
                  ({booking.leg.destination.airport.icao})
                </Text>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Date
              </Text>
              <Text display="flex" fontSize="sm" gap={2}>
                {toDate(booking.leg.departure.date.local)}
              </Text>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Time
              </Text>
              <Text display="flex" fontSize="sm" gap={2}>
                {booking.leg.departure.time.local} -{' '}
                {booking.leg.destination.time.local}
                <Text color="gray.500">
                  (
                  <ReactTimeago
                    date={`${booking.leg.departure.date.local} ${booking.leg.departure.time.local}`}
                  />
                  )
                </Text>
              </Text>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Meeting point
              </Text>
              <Box display="flex" fontSize="sm" gap={2}>
                General Aviation Terminal (GAT)
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Aircraft type
              </Text>
              <Box display="flex" fontSize="sm" gap={2}>
                {booking.leg.aircraft.type}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />

        <Box pt={8}>
          <Box py={2}>
            <Heading as="h2" size="sm">
              Passenger
            </Heading>
          </Box>
          <VStack
            alignItems="flex-start"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
          >
            {booking.passenger.map((passenger) => (
              <Box
                display="flex"
                key={passenger.passnumber}
                justifyContent="space-between"
                width="full"
              >
                <Box>
                  <Text fontSize="md">
                    {passenger.firstname} {passenger.middlename}{' '}
                    {passenger.lastname}
                  </Text>
                  <Text color="gray.500" fontSize="sm">
                    Passport No: {passenger.passnumber} (Expiration date:{' '}
                    {passenger.passExpirationDate})
                  </Text>
                </Box>
                <Box>
                  <Text>{toCurrency(flightprice)}</Text>
                </Box>
              </Box>
            ))}
          </VStack>
        </Box>

        <Box py={8}>
          <Box
            bgColor="blue.50"
            borderLeftWidth={4}
            borderLeftColor="blue.400"
            p={4}
          >
            <Box display="flex" gap={3}>
              <Box flexShrink={0}>
                <Icon as={FaSuitcaseRolling} color="blue.400" w={5} h={5} />
              </Box>
              <Box color="blue.700" fontSize="sm">
                <Heading
                  as="h3"
                  color="blue.800"
                  fontSize="sm"
                  fontWeight="medium"
                >
                  Baggage allowance per passenger:
                </Heading>
                <UnorderedList marginTop={2}>
                  <ListItem>1 item of baggage, up to 20kg</ListItem>
                  <ListItem>
                    1 item of carry-on baggage, up to 8kg (55x40x20cm)
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider mb={8} />
        <Box
          bgColor="gray.100"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          rounded="lg"
          p={8}
        >
          <Box>
            <Text fontSize="sm" fontWeight="bold">
              Billing address
            </Text>
            <Text color="gray.500" fontSize="sm" mt={2}>
              {booking.invoiceAddress?.firstname}{' '}
              {booking.invoiceAddress?.lastname}
            </Text>
            <Text color="gray.500" fontSize="sm">
              {booking.invoiceAddress?.street}
            </Text>
            <Text color="gray.500" fontSize="sm">
              {booking.invoiceAddress?.postalCode}{' '}
              {booking.invoiceAddress?.city}
            </Text>
          </Box>
          <Box>
            <Text fontSize="sm" fontWeight="bold">
              Payment information
            </Text>
            <Box display="flex" gap={4} mt={2}>
              <CreditCardIcon cardType={undefined} size="large" />
              <Box>
                <Text color="gray.900" fontSize="sm" fontWeight="medium">
                  Ending with {booking.payment?.cardnumber.slice(-4)}
                </Text>
                <Text color="gray.500" fontSize="sm">
                  Expires {booking.payment?.cardExpirationDate}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box gridColumn="3 / span 2">
            <VStack
              alignItems="flex-start"
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
            >
              <Box display="flex" justifyContent="space-between" width="full">
                <Text color="gray.500" fontSize="sm">
                  Passenger
                </Text>
                <Text>{toCurrency(booking.subtotal)}</Text>
              </Box>
              <Box display="flex" justifyContent="space-between" width="full">
                <Text color="gray.500" fontSize="sm">
                  Taxes
                </Text>
                <Text>{toCurrency(booking.taxes)}</Text>
              </Box>
              {booking.luxuryTaxes !== 0 ? (
                <Box display="flex" justifyContent="space-between" width="full">
                  <Text color="gray.500" fontSize="sm">
                    Luxury taxes (Italy)
                  </Text>
                  <Text>{toCurrency(booking.luxuryTaxes)}</Text>
                </Box>
              ) : null}
              <Box display="flex" justifyContent="space-between" width="full">
                <Text color="gray.500" fontSize="sm">
                  Service Fee
                </Text>
                <Text>{toCurrency(booking.serviceFee)}</Text>
              </Box>
              <Box display="flex" justifyContent="space-between" width="full">
                <Text fontSize="sm" fontWeight="medium">
                  Total
                </Text>
                <Text fontWeight="medium">{toCurrency(booking.total)}</Text>
              </Box>
            </VStack>
          </Box>
        </Box>
        <Box display="flex" gap={4} justifyContent="center" p={8}>
          <NavLink to="/bookings">
            <Button colorScheme="brand" onClick={onOpen} variant="outline">
              Go back
            </Button>
          </NavLink>
          {!booking.isCancelled && (
            <Button colorScheme="brand" onClick={onOpen} variant="outline">
              Cancel flight
            </Button>
          )}
        </Box>
        <Box>
          <CancellationInformation />
        </Box>
      </div>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel flight
            </AlertDialogHeader>

            <AlertDialogBody>
              <Box display="grid" gap={2}>
                <Text>
                  Are you sure? You cannot undo this action afterwards.
                </Text>
                <Text>
                  In case of cancellation by you we will have to charge you with
                  a cancellation fee.
                </Text>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Abort
              </Button>
              <Button
                colorScheme="red"
                isLoading={cancelLoading}
                loadingText="Cancelling flight"
                onClick={handleCancelFlight}
                ml={3}
              >
                Cancel flight
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default BookingDetails
