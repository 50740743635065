import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'

import { useAuth } from '../AuthProvider'
import { ReactComponent as Logo } from './logo_full.svg'

// export const action: ActionFunction = async ({ request, params }) => {
//   const formData = await request.formData();
//   const updates = Object.fromEntries(formData);
//   await updateContact(params.contactId, updates);
//   return redirect(`/contacts/${params.contactId}`);
// }

type LoginForm = {
  username: string
  password: string
}

const Login: React.FC = () => {
  const { signin } = useAuth()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<LoginForm>()

  const handleSignin = async (values: LoginForm) => {
    try {
      await signin({ ...values })
    } catch {
      console.error('An error occurred...')
      reset()
      setFocus('username')
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={8}
      justifyContent="center"
      minHeight="100vh"
    >
      <Box maxWidth="md" mx="auto" width="full">
        <Box as={Logo} color="blackAlpha.700" mx="auto" width="96" />
        <Heading
          srOnly
          fontSize="3xl"
          fontWeight="semibold"
          letterSpacing="tight"
          marginTop={6}
          textAlign="center"
        >
          Log in to your acount
        </Heading>
      </Box>

      <Box maxWidth="md" mx="auto" width="full">
        <Box bgColor="white" p={8} rounded="lg" shadow="base">
          <form onSubmit={handleSubmit(handleSignin)}>
            <VStack spacing={4}>
              <Heading
                fontSize="3xl"
                fontWeight="semibold"
                letterSpacing="tight"
                marginBottom={6}
                textAlign="center"
              >
                Log in to Traveller
              </Heading>

              <Box width="full">
                <FormControl isInvalid={errors.username !== undefined}>
                  <FormLabel>Username</FormLabel>
                  <Input
                    {...register('username', {
                      required: 'This field is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.username && errors.username.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box width="full">
                <FormControl isInvalid={errors.password !== undefined}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    {...register('password', {
                      required: 'This field is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </VStack>

            <Button
              colorScheme="brand"
              isDisabled={isSubmitting}
              marginTop={8}
              type="submit"
              width="full"
            >
              Login
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

export default Login
