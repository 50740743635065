import { Outlet } from 'react-router-dom'

import Error from '../Error'
import { useUser } from '../UserProvider'

const RequireUser: React.FC = () => {
  const { user } = useUser()

  if (!user) {
    return <Error />
  }

  return <Outlet />
}

export default RequireUser
