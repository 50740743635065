import {
  Box,
  Button,
  Divider,
  Heading,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { toCurrency, toDate } from '../../utils/format'
import CancellationInformation from '../CancellationInformation'
import CreditCardIcon from '../CreditCardIcon'
import {
  AddressInfo,
  FlightInfo,
  FlightPrice,
  PassengerInfo,
  PaymentInfo,
} from '../Flights/Flights'
import Steps from '../Steps'
import { ReactComponent as Logo } from './airport.svg'

type BookingConfirmationProps = {
  bookingNumber: string
  flight: FlightInfo
  invoiceAddress: AddressInfo
  passengers: PassengerInfo[]
  payment: PaymentInfo
  price: FlightPrice
}

const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
  bookingNumber,
  flight,
  invoiceAddress,
  passengers,
  payment,
  price,
}) => {
  useEffect(() => {
    scrollTo({ behavior: 'smooth', top: 0 })
  }, [])

  return (
    <Box>
      <Box pb={8} pt={16}>
        <Steps
          steps={[
            { name: 'Passenger details', status: 'complete' },
            // { name: 'Contact details', status: 'upcoming' },
            { name: 'Payment details', status: 'complete' },
            { name: 'Confirmation', status: 'current' },
          ]}
        />
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pb={12}
        pt={2}
      >
        <Text color="purple.500" fontSize="sm" fontWeight="bold">
          Reservation successful!
        </Text>
        <Heading>Thanks for booking</Heading>
        <Text color="gray.500" marginTop={4}>
          We appreciate your order and wish you a pleasent flight with
          PADAVIATION.
        </Text>
      </Box>
      {false && (
        <Box display="flex" justifyContent="center" pb={12}>
          <Box as={Logo} color="brand.500" h={64} w={64} />
        </Box>
      )}
      <Box display="grid" gap={2} justifyItems="center">
        <Text fontSize="xl" fontWeight="bold">
          Booking number
        </Text>
        <Text color="purple.500" fontSize="xl" fontWeight="bold">
          {bookingNumber}
        </Text>
      </Box>
      <Divider my={4} />
      <Box marginTop={8}>
        <Box>
          <Heading as="h2" size="sm">
            Flight summary
          </Heading>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          rowGap={4}
          py={5}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Departure
            </Text>
            <Box display="flex" fontSize="sm" gap={2}>
              {flight.departure.name}{' '}
              <Text color="gray.500">({flight.departure.icao})</Text>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Destination
            </Text>
            <Box display="flex" fontSize="sm" gap={2}>
              {flight.destination.name}{' '}
              <Text color="gray.500">({flight.destination.icao})</Text>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Aircraft type
            </Text>
            <Box display="flex" fontSize="sm" gap={2}>
              {flight.aircraft}
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Date
            </Text>
            <Text display="flex" fontSize="sm" gap={2}>
              {toDate(flight.date || '')}
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Text color="gray.500" fontSize="sm" fontWeight="medium">
              Time
            </Text>
            <Text display="flex" fontSize="sm" gap={2}>
              {flight.departureTime} - {flight.arrivalTime}
            </Text>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box py={2}>
          <Heading as="h2" size="sm">
            Passenger
          </Heading>
        </Box>
        <VStack
          alignItems="flex-start"
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
        >
          {passengers.map((passenger) => (
            <Box
              display="flex"
              key={passenger.passnumber}
              justifyContent="space-between"
              width="full"
            >
              <Box>
                <Text>
                  {passenger.firstname} {passenger.middlename}{' '}
                  {passenger.lastname}
                </Text>
                <Text>
                  Passport No: {passenger.passnumber} (Expiration date:{' '}
                  {passenger.passExpiryDate})
                </Text>
              </Box>
              <Box>{toCurrency(flight.price.net)}</Box>
            </Box>
          ))}
        </VStack>
      </Box>
      <Box
        bgColor="gray.100"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        rounded="lg"
        p={8}
      >
        <Box>
          <Text fontSize="sm" fontWeight="bold">
            Billing address
          </Text>
          <Text color="gray.500" fontSize="sm" mt={2}>
            {invoiceAddress.firstname} {invoiceAddress.lastname}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {invoiceAddress.street}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {invoiceAddress.postalCode} {invoiceAddress.city}
          </Text>
        </Box>
        <Box>
          <Text fontSize="sm" fontWeight="bold">
            Payment information
          </Text>
          <Box display="flex" gap={4} mt={2}>
            <CreditCardIcon cardType={payment.cardtype} size="large" />
            <Box>
              <Text color="gray.900" fontSize="sm" fontWeight="medium">
                Ending with {payment.cardnumber.slice(-4)}
              </Text>
              <Text color="gray.500" fontSize="sm">
                Expires {payment.expirationDate}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="3 / span 2">
          <VStack
            alignItems="flex-start"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
          >
            <Box display="flex" justifyContent="space-between" width="full">
              <Text color="gray.500" fontSize="sm">
                Passenger
              </Text>
              <Text>{toCurrency(price.subtotal)}</Text>
            </Box>
            <Box display="flex" justifyContent="space-between" width="full">
              <Text color="gray.500" fontSize="sm">
                Taxes
              </Text>
              <Text>{toCurrency(price.taxes)}</Text>
            </Box>
            {flight.price.luxuryTax !== 0 ? (
              <Box display="flex" justifyContent="space-between" width="full">
                <Text color="gray.500" fontSize="sm">
                  Luxury Tax
                </Text>
                <Text>{toCurrency(price.luxuryTax)}</Text>
              </Box>
            ) : null}
            <Box display="flex" justifyContent="space-between" width="full">
              <Text color="gray.500" fontSize="sm">
                Service Fee
              </Text>
              <Text>{toCurrency(price.serviceFee)}</Text>
            </Box>
            <Box display="flex" justifyContent="space-between" width="full">
              <Text fontSize="sm" fontWeight="medium">
                Total
              </Text>
              <Text fontWeight="medium">{toCurrency(price.total)}</Text>
            </Box>
          </VStack>
        </Box>
      </Box>
      <Box display="flex" gap={4} justifyContent="center" p={8}>
        <NavLink to="/bookings">
          <Button colorScheme="purple" variant="outline">
            View my bookings
          </Button>
        </NavLink>
      </Box>
      <Box>
        <CancellationInformation />
      </Box>
    </Box>
  )
}

export default BookingConfirmation
