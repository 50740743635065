import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type BookingsQueryVariables = Types.Exact<{ [key: string]: never }>

export type BookingsQuery = {
  __typename?: 'Query'
  bookedFlights: Array<{
    __typename?: 'BookedFlight'
    id: number
    bookingNumber: string
    total: number
    isCancelled: boolean
    leg: {
      __typename?: 'FlightLeg'
      departure: {
        __typename?: 'FlightLocationDetails'
        airport: { __typename?: 'Airport'; icao: string; name: string }
        date: { __typename?: 'LegDate'; local: any }
        time: { __typename?: 'LegTime'; local: any }
      }
      destination: {
        __typename?: 'FlightLocationDetails'
        airport: { __typename?: 'Airport'; icao: string; name: string }
        time: { __typename?: 'LegTime'; local: any }
      }
    }
  }>
}

export const BookingsDocument = gql`
  query Bookings {
    bookedFlights {
      id
      bookingNumber
      leg {
        departure {
          airport {
            icao
            name
          }
          date {
            local
          }
          time {
            local
          }
        }
        destination {
          airport {
            icao
            name
          }
          time {
            local
          }
        }
      }
      total
      isCancelled
    }
  }
`

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingsQuery(
  baseOptions?: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(
    BookingsDocument,
    options
  )
}
export function useBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingsQuery,
    BookingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(
    BookingsDocument,
    options
  )
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>
export type BookingsLazyQueryHookResult = ReturnType<
  typeof useBookingsLazyQuery
>
export type BookingsQueryResult = Apollo.QueryResult<
  BookingsQuery,
  BookingsQueryVariables
>
