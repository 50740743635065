import { Text } from '@chakra-ui/react'

const CancellationInformation: React.FC = () => {
  return (
    <Text color="gray.500" fontSize="sm">
      This contract is not depending on given slots by airport. This contract
      guarantees no flexibility in departure time. Please be aware that PAD
      Aviation may cancel the flight until 1h before scheduled departure at no
      cost. Any expenses paid by the passenger(s) arising as a result of the
      cancellation will not be reimbursed by PADAVIATION. If the passenger(s)
      won`t be at airport for departure on time the aircraft will be released
      and the client has to pay the full contracted amount. Please be aware that
      single aircraft registrations cannot be guaranteed. All flights may be
      operated on quoted aircraft type or superior. The passenger(s) shall pay a
      100% cancellation fee if flight booked with PADAVIATION is cancelled less
      than 1h before scheduled departure.
    </Text>
  )
}

export default CancellationInformation
