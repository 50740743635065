import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useDisclaimer } from '../DisclaimerProvider'
import { ReactComponent as Logo } from './agreement.svg'

type AgreementForm = {
  accepted: boolean
}

const Disclaimer: React.FC = () => {
  const { accept } = useDisclaimer()
  const navigate = useNavigate()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<AgreementForm>({
    defaultValues: { accepted: false },
  })

  const handleAccept = () => {
    accept()
    navigate('/company')
  }

  return (
    <Box maxWidth="2xl" mx="auto">
      <form onSubmit={handleSubmit(handleAccept)}>
        <Box display="grid" gap={4}>
          <Box display="flex" justifyContent="center" pb={8}>
            <Box as={Logo} color="brand.500" h={64} w={64} />
          </Box>
          <Heading pb={4}>Agreement conditions</Heading>
          <Text>
            Unless otherwise stated, PAD Aviation Service GmbH and/or its
            licensors own the intellectual property rights in the website and
            material on the website. Subject to the license below, all these
            intellectual property rights are reserved.
          </Text>
          <Text>
            You may view, download for caching purposes only, and print pages
            from the website for your own personal use, subject to the
            restrictions set out below and elsewhere in these terms and
            conditions.
          </Text>
          <Text>You must not:</Text>
          <UnorderedList>
            <ListItem>
              republish material from this website (including republication on
              another website)
            </ListItem>
            <ListItem>
              sell, rent or sub-license material from the website
            </ListItem>
            <ListItem>show any material from the website in public</ListItem>
            <ListItem>
              reproduce, duplicate, copy or otherwise exploit material on this
              website for a commercial purpose
            </ListItem>
            <ListItem>
              edit or otherwise modify any material on the website
            </ListItem>
            <ListItem>
              redistribute material from this website [except for content
              specifically and expressly made available for redistribution]
            </ListItem>
          </UnorderedList>
          <Text>
            Any violation will be prosecuted and will result in ban of the
            specific passenger from all PADAVIATION flights.
          </Text>
          <FormControl isInvalid={errors.accepted !== undefined}>
            <Checkbox
              colorScheme="brand"
              {...register('accepted', {
                required: 'You have to accept our agreement conditions.',
              })}
            >
              Accept agreement conditions
            </Checkbox>
            <FormErrorMessage>
              {errors.accepted && errors.accepted.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box py={6}>
          <Button colorScheme="brand" isLoading={isSubmitting} type="submit">
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default Disclaimer
