import { Box, Heading, Text } from '@chakra-ui/react'

type SectionHeaderProps = {
  title: string
  description?: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  description,
  title,
}) => {
  return (
    <Box borderBottomWidth={0} pb={5}>
      <Heading as="h2" size="sm">
        {title}
      </Heading>
      {description && (
        <Text color="gray.500" fontSize="sm" mt={2}>
          {description}
        </Text>
      )}
    </Box>
  )
}

export default SectionHeader
