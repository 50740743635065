import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useRef } from 'react'

import { useCancelFlightByPadMutation } from './mutation.generated'

type CancellationProps = {
  isDisabled?: boolean
  flightId: number
  onCancel: () => void
}

const Cancellation: React.FC<CancellationProps> = ({
  isDisabled,
  flightId,
  onCancel,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const toast = useToast()

  const [cancelFlight, { loading }] = useCancelFlightByPadMutation()

  const handleCancellation = async () => {
    const { data } = await cancelFlight({ variables: { flight: flightId } })

    if (data?.cancelFlightByPAD) {
      toast({
        description: 'The flight has been cancelled succesfully',
        status: 'success',
        title: 'Flight cancelled',
      })
      onCancel()
    } else {
      toast({
        description:
          'Sorry, the flight could not be cancelled. Please try again',
        status: 'error',
        title: 'Error occurred',
      })
    }
  }

  return (
    <>
      <Button
        colorScheme="red"
        isDisabled={isDisabled}
        onClick={onOpen}
        variant="link"
      >
        Cancellation
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel flight
            </AlertDialogHeader>

            <AlertDialogBody>
              <Box display="grid" gap={2}>
                <Text>
                  Are you sure? You cannot undo this action afterwards.
                </Text>
                <Text>
                  In case of cancellation by PADAVIATION the traveller will{' '}
                  <strong>not</strong> be charged with a cancellation fee.
                </Text>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Abort
              </Button>
              <Button
                colorScheme="red"
                isLoading={loading}
                loadingText="Cancelling"
                onClick={handleCancellation}
                ml={3}
              >
                Cancel flight
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Cancellation
