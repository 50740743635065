import dayjs from 'dayjs'

export const toCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions
): string =>
  Intl.NumberFormat('de-DE', {
    currency: 'EUR',
    style: 'currency',
    ...options,
  }).format(value)

export const toDate = (
  value: string,
  options?: { displayWeekday?: boolean }
): string =>
  dayjs(value).format(
    options?.displayWeekday ? 'dddd, DD MMM YYYY' : 'DD MMM YYYY'
  )
