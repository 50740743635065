import * as Types from '../../generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

const defaultOptions = {} as const
export type FlightsQueryVariables = Types.Exact<{
  date: Types.Scalars['String']
  enddate: Types.Scalars['String']
  offset: Types.Scalars['Int']
}>

export type FlightsQuery = {
  __typename?: 'Query'
  legs: Array<{
    __typename?: 'FlightLeg'
    id: number
    numberOfPassenger: string
    departure: {
      __typename?: 'FlightLocationDetails'
      airport: { __typename?: 'Airport'; icao: string; name: string }
      date: { __typename?: 'LegDate'; local: any }
      time: { __typename?: 'LegTime'; local: any }
    }
    destination: {
      __typename?: 'FlightLocationDetails'
      airport: { __typename?: 'Airport'; icao: string; name: string }
      time: { __typename?: 'LegTime'; local: any }
    }
    aircraft: { __typename?: 'Aircraft'; type: string }
    prices: { __typename?: 'LegPrice'; net: number; luxuryTax: number }
  }>
}

export const FlightsDocument = gql`
  query Flights($date: String!, $enddate: String!, $offset: Int!) {
    legs(
      filter: { departureDate: { _gte: $date, _lte: $enddate } }
      offset: $offset
      sort: [{ by: departureDate }, { by: departureTime }]
    ) {
      id
      departure {
        airport {
          icao
          name
        }
        date {
          local
        }
        time {
          local
        }
      }
      destination {
        airport {
          icao
          name
        }
        time {
          local
        }
      }
      aircraft {
        type
      }
      prices {
        net
        luxuryTax
      }
      numberOfPassenger
    }
  }
`

/**
 * __useFlightsQuery__
 *
 * To run a query within a React component, call `useFlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlightsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      enddate: // value for 'enddate'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFlightsQuery(
  baseOptions: Apollo.QueryHookOptions<FlightsQuery, FlightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FlightsQuery, FlightsQueryVariables>(
    FlightsDocument,
    options
  )
}
export function useFlightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlightsQuery, FlightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FlightsQuery, FlightsQueryVariables>(
    FlightsDocument,
    options
  )
}
export type FlightsQueryHookResult = ReturnType<typeof useFlightsQuery>
export type FlightsLazyQueryHookResult = ReturnType<typeof useFlightsLazyQuery>
export type FlightsQueryResult = Apollo.QueryResult<
  FlightsQuery,
  FlightsQueryVariables
>
