import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { baseURL } from '../../utils/config'

const STORAGE_KEY = 'trv_auth'

type SigninValues = {
  username: string
  password: string
}

type Auth = {
  isPAD: boolean
  token: string
}

type AuthContextType = {
  auth: Auth | null
  signin: (values: SigninValues) => Promise<void>
  signout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<Auth | null>(() => {
    const storedAuth = window.localStorage.getItem(STORAGE_KEY)
    return storedAuth ? JSON.parse(storedAuth) : null
  })
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (auth) {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(auth))
    } else {
      window.localStorage.removeItem(STORAGE_KEY)
    }
  }, [auth])

  const signin = async ({ username, password }: SigninValues) => {
    try {
      const response = await fetch(`${baseURL}/Auth/traveller`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mode: 'signin',
          values: { username: username, password: password },
        }),
      })
      const data = await response.json()

      setAuth({
        isPAD: data.is_pad,
        token: data.access_token,
      })

      const origin = location.state?.from?.pathname || '/'
      navigate(origin)
    } catch {
      return Promise.reject('Invalid credentials')
    }
  }

  const signout = () => {
    setAuth(null)
    navigate('/')
  }

  return (
    <AuthContext.Provider value={{ auth, signin, signout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const value = useContext(AuthContext)

  if (value === null) {
    throw new Error('useAuth must be used within <AuthProvider />')
  }
  return value
}

export default AuthProvider
