import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useUser } from '../UserProvider'
import { ReactComponent as Logo } from './undraw_personal_site_re_c4bp.svg'

type CompanySelectorForm = {
  airline: string
  emyployeeId: string
}

const CompanySelector: React.FC = () => {
  const { changeUser } = useUser()
  const navigate = useNavigate()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<CompanySelectorForm>({
    defaultValues: { airline: '', emyployeeId: '' },
  })

  const handleContinue = async (values: CompanySelectorForm) => {
    try {
      await changeUser(values.airline, values.emyployeeId)
      navigate('/flights')
    } catch {
      console.log('error')
    }
  }

  return (
    <Box maxWidth="2xl" mx="auto">
      <form onSubmit={handleSubmit(handleContinue)}>
        <VStack spacing={4}>
          {true && (
            <Box display="flex" justifyContent="center" pb={8}>
              <Box as={Logo} color="brand.500" h={64} w={64} />
            </Box>
          )}
          <Box width="full">
            <FormControl isInvalid={errors.airline !== undefined}>
              <FormLabel htmlFor="airline">Airline</FormLabel>
              <Select
                autoComplete="off"
                id="airline"
                {...register('airline', {
                  required: 'This is required',
                })}
              >
                <option value="Air New Zealand">Air New Zealand</option>
                <option value="Austrian Airlines">Austrian Airlines</option>
                <option value="Avianca">Avianca</option>
                <option value="Brussels Airlines">Brussels Airlines</option>
                <option value="Condor">Condor</option>
                <option value="Delta Air Lines">Delta Air Lines</option>
                <option value="Edelweiss">Edelweiss</option>
                <option value="Eurowings">Eurowings</option>
                <option value="Eurowings Discover">Eurowings Discover</option>
                <option value="Finnair">Finnair</option>
                <option value="LOT Polish Airlines">LOT Polish Airlines</option>
                <option value="Lufthansa">Lufthansa</option>
                <option value="Lufthansa Cityline">Lufthansa Cityline</option>
                <option value="Qantas">Qantas</option>
                <option value="Scandinavian Airlines">
                  Scandinavian Airlines
                </option>
                <option value="Singapore Airlines">Singapore Airlines</option>
                <option value="Southwest Airlines">Southwest Airlines</option>
                <option value="Swiss">Swiss</option>
                <option value="TAP Air Portugal">TAP Air Portugal</option>
                <option value="TUIfly">TUIfly</option>
                <option value="Turkish Airlines">Turkish Airlines</option>
                <option value="United Airlines">United Airlines</option>
                <option value="Volotea">Volotea</option>
                <option value="Vueling Airlines">Vueling Airlines S.A.</option>
              </Select>
              <FormErrorMessage>
                {errors.airline && errors.airline.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box width="full">
            <FormControl isInvalid={errors.emyployeeId !== undefined}>
              <FormLabel>Employee ID</FormLabel>
              <Input
                autoComplete="off"
                {...register('emyployeeId', {
                  required: 'This field is required',
                })}
              />
              <FormErrorMessage>
                {errors.emyployeeId && errors.emyployeeId.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>

        <Button
          colorScheme="brand"
          isDisabled={isSubmitting}
          marginTop={8}
          type="submit"
        >
          Continue
        </Button>
      </form>
    </Box>
  )
}

export default CompanySelector
