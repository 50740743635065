import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { baseURL } from '../../utils/config'
import { useAuth } from '../AuthProvider'

const STORAGE_KEY = 'trv_user'

type User = {
  companyName: string
  employeeId: string
  token: string
}

type UserContextType = {
  changeUser: (companyName: string, employeeId: string) => Promise<void>
  user: User | null
}

const UserContext = createContext<UserContextType | null>(null)

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = window.localStorage.getItem(STORAGE_KEY)
    return storedUser ? JSON.parse(storedUser) : null
  })
  const { auth } = useAuth()

  useEffect(() => {
    if (!auth) {
      setUser(null)
    }
  }, [auth])

  useEffect(() => {
    if (user) {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(user))
    } else {
      window.localStorage.removeItem(STORAGE_KEY)
    }
  }, [user])

  const changeUser = async (companyName: string, employeeId: string) => {
    try {
      const response = await fetch(`${baseURL}/Auth/traveller`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mode: 'company',
          values: { companyName: companyName, employeeId: employeeId },
        }),
      })
      const data = await response.json()
      setUser({ companyName, employeeId, token: data.token })
    } catch {
      return Promise.reject('Invalid selection')
    }
  }

  return (
    <UserContext.Provider value={{ changeUser, user }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const value = useContext(UserContext)

  if (value === null) {
    throw new Error('useUser must be used within <UserProvider />')
  }

  return value
}

export default UserProvider
